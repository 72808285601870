.technologies {
    background: linear-gradient(40deg, #727575, #ebe7e7 100%);
    max-width: 100%;
    box-sizing: border-box;
    color: white;

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        position: relative;
        padding: 1rem 0;
        margin-top: 0;
        color: black;

        &::after {
            content: "";
            display: block;
            width: 50%;
            border-bottom: 1px solid #832121;
            position: absolute;
            bottom: 0;
            left: 25%;
        }
    }

    .technologies__intro {
        // font-size: 1rem;
        margin: auto;
        // padding: 0.5rem 1rem;
        max-width: 900px;
        // background-color: #f0eded;
        // border-radius: 10px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        color: black;
        text-align: justify;
        line-height: 1.5;
        padding: 1rem 2rem;

        /* Espacement des lignes pour une meilleure lisibilité */
        // font-style: italic;
        // @media screen and (max-width: 975px) {
        //     margin: 1rem;
        // }
    }

    /* Style pour les liens dans votre texte d'introduction */
    .technologies__intro a {
        color: #832121;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: #e54d4d;
            /* Changement de couleur au survol */
        }
    }

    .technologies__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 1rem 0 1rem;
    }

    .technologies__buttons button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #b37d7d;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s;
        border: none;

        &:hover {
            background-color: #832121;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .technologies__buttons button.active {
        background-color: #832121;
        color: #fff;
    }
    




    &__flex {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        padding-bottom: 2rem;
        max-width: 1100px;
        margin: 0 auto;

        .card {
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            margin: 1rem;

            &__content {
                padding: 1rem;

                &__title {
                    font-size: 1.2rem;
                    font-weight: bold;
                    margin: 0;
                    color: white;
                }

                &__description {
                    font-size: 1rem;
                    color: white;
                    margin-top: 0.5rem;

                    li {
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}

.skill {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2em;
}

.skill-logo {
    width: 50px; // Modifiez la largeur selon vos préférences
    height: auto;
    border-radius: 50%; // Pour des coins arrondis
    margin-right: 0.5em;
    vertical-align: middle; // Pour l'alignement vertical avec le texte
    transition: transform 0.3s ease-in-out; // Pour l'effet de survol

    &:hover {
        transform: scale(1.1); // Zoom sur l'image au survol
    }
}

.skill-name {
    font-size: 1.5em;
    margin-bottom: 1em;
}


.front-end,
.back-end,
.tools {
    border: 1px solid #ddd;
    padding: 2em;
    margin: 1em 0;
    border-radius: 8px;
}


.technologyCard {
    perspective: 1000px;
    width: 150px;
    height: 150px;
    padding: 1rem;
    margin: 1rem;
}

.technology-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.technologyCard:hover .technology-inner {
    transform: rotateY(180deg);
}

.technology-inner.flipped {
    transform: rotateY(180deg);
}



.technologyCard:hover .technology-inner {
    transform: rotateY(180deg);
}

.technology-front,
.technology-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.technology-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.technology-front {
    z-index: 2;
    cursor: pointer;
}

.technology-back {
    transform: rotateY(180deg);
    background-color: #f0eded;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 100%;
    height: 100%;

}

a {
    color: #832121,
}

.data-project {
margin: 0;
}

.data-link {
font-weight: lighter;
margin: 0;
}

.data-learning {
font-weight: lighter;
font-style: italic;
}