.navbar-mobile {
    @media screen and (min-width: 1000px) {
        display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5%;
    z-index: 100;
    background-color: #282c34;


    .logo {
        // flex: 1;
        // width: 3rem;
        // height: 3rem;
        // max-width: 3rem;
        // border-radius: 100%;
            color: white;
            font-weight: bold;
            &__happy{
                color : #ef5e5e;
            }
    }

    .menu-burger {
        cursor: pointer;
        width: 25px;
        height: 25px;
    }

    .mobile-navigation {
        position: absolute;
        background-color: #282c34;
        top: 100%;
        left: 0;
        width: 100%;
        // height: 100vh;
        z-index: 1;

        .modal-content {
            padding: 1rem;

            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                font-size: 1.5rem;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 1rem;
                    border-top: 1px solid #ddd;

                    &:first-child {
                        border-top: none;
                    }

                    a {
                        text-decoration: none;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}