.education-and-career {
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    &__section {
        position: relative;

        &.sport-section {
            background: linear-gradient(40deg, #727575, #ebe7e7 100%);
        }

        &.dev-section {
            background: linear-gradient(40deg, #6b7070, #282c34 100%);
            color: white;
        }

        @media screen and (min-width: 1000px) {
            padding: 0 5%;
        }
    }

    padding: 0rem;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    &__title {
        padding-top: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        position: relative;
        padding-bottom: 1rem;
        margin-top: 0;
    }

    &__title::after {
        content: "";
        display: block;
        width: 50%;
        border-bottom: 1px solid #832121;
        position: absolute;
        bottom: 0;
        left: 25%;
    }

    &__subtitle {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }

    &__grid {
        display: grid;
        gap: 0rem;
        align-items: stretch;
        overflow: hidden;
    }

    .card {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        padding: 1rem;
        max-width: 1100px;
        margin: 0 auto;

        &.reverse {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.6;
            transition: opacity 0.3s ease;
            box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5) inset, 0px -20px 20px rgba(0, 0, 0, 0.5) inset;
            border-radius: 10%;

            @media screen and (max-width: 806px) {
                margin-bottom: 2rem;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: auto;
            // text-align: center;
        }

        &__title {
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 0;
        }

        &__description {
            text-align: justify;
            font-size: 1rem;
            margin: 1rem 2rem;
        }
    }

    @media screen and (max-width: 806px) {
        .card, 
        .card.reverse {
            // text-align: center;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }
    
        .card__image {
            order: 1; /* first */
        }
    
        .card__content {
            order: 2; /* second */
        }
    
        .card.reverse__image {
            order: 3; /* third */
        }
    
        .card.reverse__content {
            order: 4; /* fourth */
        }
    }
}

.background {
    background: linear-gradient(40deg, #6b7070, #282c34 100%);
    color: white;
}
