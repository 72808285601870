.about-me {
  text-align: center;
  // padding: 2rem;
  background-color: #f8f8f8;

  @media screen and (min-width: 1000px) {
    padding: 0 5%;
    &__intro, &__qualities {
      flex-basis: 48%;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
    margin-top: 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 1rem 1rem 1rem;
  }

  &__subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); // Ajout d'une ombre pour améliorer la lisibilité
  }

  &__intro {
    flex: 1;
    text-align: start;
    background-color: #e0e0e0; // Une couleur de fond légère pour contraster avec le reste
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &__qualities {
    background-size: cover;
    background-position: center;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); // Un overlay pour assombrir l'image et rendre le texte plus lisible
      border-radius: 10px;
    }

    & > * {
      position: relative; // Pour que le contenu apparaisse au-dessus de l'overlay
    }
  }

  &__loisirs {
    background-color: #e0e0e0;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  
    &-content {
      display: flex;
      align-items: center;
      gap: 1rem;
      max-width: 1100px;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      img {
        width: 100px;
        height: auto;
        border-radius: 0 0 50% 50%;
      }
  
      &-text {
        flex-grow: 1; // Pour que le texte prenne toute la place disponible
        text-align: center;
      }
    }
  
    &-activities {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      margin-top: 1rem;
    
      img {
        width: 150px; 
        height: 150px; 
        max-width: 100%; 
        border-radius: 10px;
        transition: transform 0.3s;
        object-fit: cover; 
    
        &:hover {
          transform: scale(1.05); 
        }
      }
    }
  }
}