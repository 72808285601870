.intro-section {

    @media screen and (min-width: 1000px) {
        padding: 0 5%;
    }
    background: linear-gradient(150deg, rgb(0, 0, 0), black 10%, #ef5e5e 50%, black 60%);

    &__img {
        display: flex;
        justify-content: center;
        filter: brightness(0.8) contrast(1.1); // Assombrissez légèrement l'image et augmentez le contraste
    }
}

.imgHome {
    max-width: 600px;
    margin-top: 7rem;
    width: 70%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    // transition: opacity 0.3s ease, filter 0.3s ease;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5) inset, 0px -20px 20px rgba(0, 0, 0, 0.5) inset;
    border-radius: 5%;
    border: 0.5px solid rgba(255, 255, 255, 0.103);
    transform: rotate(-10deg);

    &:hover {
        opacity: 1;
        filter: brightness(1) contrast(1); // Supprimez le filtre lorsqu'il est survolé
    }
}

.intro-text {
    position: relative;
    z-index: 2;
    padding: 2rem;
    font-size: 1rem;
    color: white;
    max-width: 1100px;
    margin: 0 auto;
    text-align: justify;

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
}

.typed-cursor {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    font-weight: 100;
}

@keyframes typedjsBlink {
    50% { opacity: 0.0; }
}

.container-cv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 2rem 0;
}

.cv {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ef5e5e;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #d95353;
    }
  }