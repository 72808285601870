.projets {
    // background: linear-gradient(40deg, #6b7070, #282c34 100%);
    background-color: #282c34;
    padding: 0rem;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        position: relative;
        padding: 1rem 0;
        margin-top: 0;
        color: white;
    }

    &__title::after {
        content: "";
        display: block;
        width: 50%;
        border-bottom: 1px solid #832121;
        position: absolute;
        bottom: 0;
        left: 25%;
    }
  
    &__carousel {
      /* Styles pour votre carrousel de projets */
      display: flex;
      justify-content: space-between;
      overflow: hidden;
    }
  }

  .carousel__title {
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: white;
    text-decoration: underline white;
  }

  .carousel-container {
    max-width: 800px;
    margin: 0 auto;
  }

