.contact-section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #333;
    color: #fff;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 5%;
      
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;

    }
  
    .social-links {
      display: flex;
      justify-content: space-evenly;
      margin: 1rem;
  
      a {
        color: #fff;
        font-size: 24px;
        transition: color 0.3s;
  
        &:hover {
          color: #0077b5; // Couleur de survol pour LinkedIn, à ajuster pour d'autres icônes
        }

        @media screen and (min-width: 1000px) {
          padding: 0 1rem;
          
        }
      }
    }
  
    .scroll-top {
      cursor: pointer;
      
      @media screen and (min-width: 1000px) {
        padding: 0 1rem;
        
      }
    }
  }

  .logo {
    width: 25px;
    height: 25px;
  }

  .arrow-up {
    width: 30px;
    height: 30px;
  }

  .name, .pro {
    margin: 0;
    text-align: center;

    @media screen and (min-width: 1000px) {
      padding: 0 3rem;
      
    }
  }

  .pro {
    font-size: 0.8rem;
    font-style: italic;
  }